import * as React from 'react';
import { useEffect, useMemo, useState } from "react";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { AppBar, Container, CssBaseline, Divider, Fab, GlobalStyles, IconButton, Link, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';

import ParticleBackground from "../home/ParticleBackground";

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
  zIndex:5,
};

const fabs = [
  {
    color: 'primary',
    sx: fabStyle,
    icon: <AddIcon />,
    label: 'Add',
  },
]

  function Copyright(props) {
    return (
      <Typography variant="body1" color="#fff" align="center" fontWeight={"regular"} {...props}>
        {'Copyright © csemoguel '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  

export default function Main({props}) {

  return (
    <>
    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none'}}} />
    <CssBaseline />
    {/* Header */}
    <AppBar elevation={1}  style={{ background: "#0A2842", boxShadow: 'none', position:"relative"}}>
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Grid noWrap sx={{ flexGrow: 1 }}>
        <Link href="/home">
        <Box
          component="img"
          sx={{
            flexGrow:1,
            height: 64,
            width: 167,
            maxHeight: { xs: 64, md: 64 },
            maxWidth: { xs: 167, md: 167 },
          }}
          alt="Logo de Aidee."
          src="https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/sce.png?alt=media&token=15d1b427-63e5-4388-913c-57d5c8e13211"
          
        >
          
        </Box>
        </Link>
        </Grid>
        <nav> 
        <Button href="/contact-us" variant='header' sx={{ my: 0.5, mx: 1.5 }}>
          Contactanos
        </Button>
        <Button href="/privacy" variant='header' sx={{ my:  0.5, mx: 1.5 }}>
          Politicas de privacidad
        </Button>
        <Button href="/terms" variant="appbar" sx={{ my:  0.5, mx: 1.5 }}>
          Terminos y condiciones
        </Button>
        </nav>
      </Toolbar>
    </AppBar>

    
    {/*End Header */}
    {props}


    {/* Footer */}
    <Container
      maxWidth="false"
      variant="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ ml: '-15px' }}>
              <img
                src={
                  'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/sce.png?alt=media&token=15d1b427-63e5-4388-913c-57d5c8e13211'
                }
              
                alt="logo of sitemark"
              />
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
            Contactanos
            </Typography>
            <Typography variant="body2" color="#FFF" mb={2}>
            Dejanos tu correo electronico y nos pondremos en contacto contigo.
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                  id="outlined-basic"
                  hiddenLabel
                  size="small"
                  variant="outlined"
                  fullWidth
                
                  aria-label="Ingresa tu correo electronico"
                  placeholder="Ingresa tu correo electronico"
                  inputProps={{
                    autocomplete: 'off',
                    ariaLabel: 'Ingresa tu correo electronico',
                  }}
              />
              <Button variant="contained"  color="primary" sx={{ flexShrink: 0 }}>
                Enviar
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Product
          </Typography>
          <Link color="#FFF" href="#">
            Features
          </Link>
          <Link color="#FFF" href="#">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Company
          </Typography>
          <Link color="#FFF" href="#">
            About us
          </Link>
          <Link color="#FFF" href="#">
            Testimonials
          </Link>
          <Link color="#FFF" href="#">
            Press
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Legal
          </Typography>
          <Link color="#FFF" href="#">
            Privacy
          </Link>
          <Link color="#FFF" href="/terms">
            Terms
          </Link>
          
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 0, sm: 0 },
          width: '100%',
        }}
      >
        
        {/*<Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color:"#FFF",
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/mui"
            aria-label="GitHub"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack> */}
        <div>
          <Stack
            direction="row"
            justifyContent="left"
            spacing={1}
            useFlexGap
            sx={{
              color:"#FFF",
            }}
          >
          
          <Stack 
              justifyContent="left"
              spacing={1}
              useFlexGap
              sx={{
                color: '#FFF',
              }}
            >
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
              Jose Clemente Orozco # 1631,
              Col. Satelite. Puebla, Puebla C.P.72320, 
            </Typography >
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
              Cel: 22 14 42 46 21
            </Typography >
              
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
              ventas@csemoguel.com
            </Typography>
          </Stack>
        </Stack>
        </div>   
      </Box>
      <Copyright />
    </Container>
     {/* End footer */}
     <a style={fabStyle} aria-label="Chat on WhatsApp" href="https://wa.me/12214424621"><img alt="Chat on WhatsApp" src="https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/WhatsAppButtonGreenSmall.svg?alt=media&token=c3f4f4cc-b802-4756-bce7-98ca88c311ac" /></a>

   </>
  );
}