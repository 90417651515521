import { Container, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';

export default function Terms () {
    return(   
        <Container variant="no-color"  component="main" sx={{ pt: { xs: 8, sm: 8 },pb: { xs: 8, sm: 8 }}}>    
            
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12} >
                        <Typography variant="home-header-black"
                            align="left"
                            component="p">
                            Terminos y condiciones
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} sm={8} >
                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        El presente documento regula los términos y condiciones generales (en adelante "TÉRMINOS Y CONDICIONES") 
                        aplicables al uso de los contenidos, productos y servicios ofrecidos a través del sitio web www.csemoguel.com 
                        (en adelante "SITIO WEB"), cuyo titular es Aidee Rodriguez Moguel (en adelante "TITULAR"), con domicilio establecido 
                        en Puebla con la siguiente dirección: Jose Clemente Orozco 1631, Colonia Satelite, Puebla, Pue. C.P. 72320.<br/>
                        
                        Toda persona que pretenda acceder o utilizar el sitio web y los servicios ofrecidos en él, podrá hacerlo únicamente 
                        sujeto a los TÉRMINOS Y CONDICIONES aquí establecidos, así como a las políticas y principios incorporados en el 
                        presente documento. En todo caso, aquella persona que no acepte los TÉRMINOS Y CONDICIONES vigentes, deberá abstenerse 
                        de utilizar el SITIO WEB y/o adquirir los productos y servicios que eventualmente sean ofrecidos en el mismo.<br/><br/>
                        </Typography>

                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                            PRIMERA. ALCANCE Y CONDICIONES DE ACCESO.<br/><br/>
                        </Typography>
                        
                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                            El propósito de los presentes TÉRMINOS Y CONDICIONES consiste en regular el acceso y
                            el uso del SITIO WEB, comprendiendo dentro de este concepto cualquier tipo de contenido, producto o servicio que esté 
                            disponible para el público en general dentro del dominio www.csemoguel.com.<br></br>
                        </Typography>
                        
                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>  
                            El TITULAR se reserva expresamente la facultad de realizar modificaciones en cualquier momento y sin previo aviso, tanto en la presentación, 
                            los contenidos, la funcionalidad, los productos, los servicios y la configuración que pudiera estar contenida 
                            en el SITIO WEB. En virtud de lo anterior, el USUARIO reconoce y acepta que el TITULAR en cualquier momento 
                            podrá interrumpir, desactivar o cancelar cualquiera de los elementos que conforman el SITIO WEB o el acceso 
                            a los mismos.
                        </Typography>

                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}> 
                            El acceso al SITIO WEB por parte del USUARIO se establece como un acceso libre y, en general, 
                            gratuito, sin que el USUARIO esté obligado a proporcionar una contraprestación para disfrutar del mismo, 
                            con excepción del costo de la conexión a internet que el propio USUARIO haya contratado con el proveedor 
                            de servicios de este tipo.
                        </Typography>
                            
                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}> 
                            El acceso a ciertos contenidos y servicios del SITIO WEB podrá llevarse a cabo 
                            mediante previa suscripción o registro del USUARIO.
                            
                            El SITIO WEB está destinado únicamente para personas que hayan alcanzado la mayoría de edad (mayores de 18 años). En este sentido, el TITULAR no asume responsabilidad 
                            alguna por el incumplimiento de este requisito. 
                        </Typography>

                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                            Es responsabilidad exclusiva del USUARIO asegurarse de cumplir con la mayoría de edad requerida para acceder y 
                            utilizar el SITIO WEB. En caso de que menores de edad accedan o utilicen el SITIO WEB, lo hacen bajo la 
                            supervisión y responsabilidad de sus padres o tutores legales. El TITULAR se reserva el derecho de solicitar 
                            documentación que acredite la mayoría de edad del USUARIO en cualquier momento y de forma discrecional.
                            El SITIO WEB ha sido diseñado principalmente para satisfacer las necesidades de USUARIOS que residen en 
                            territorio mexicano. Es importante destacar que el TITULAR no puede asegurar que el SITIO WEB cumpla con todas las 
                            leyes y regulaciones de otros países fuera de México. Si un USUARIO reside en otro país o tiene su domicilio fuera 
                            de México y decide acceder o utilizar el SITIO WEB, lo hace bajo su propio riesgo y responsabilidad. 
                        </Typography>

                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                            El USUARIO debe asegurarse de que el acceso y uso del SITIO WEB cumplan con las leyes y regulaciones locales que le sean aplicables en su país de residencia. 
                            El TITULAR no asume ninguna responsabilidad por las consecuencias legales o problemas que puedan surgir del acceso o uso del SITIO WEB desde ubicaciones 
                            fuera de México. El TITULAR puede optar por administrar y gestionar el SITIO WEB de manera directa o a 
                            través de un tercero autorizado. Esta decisión no afectará en ningún aspecto los TÉRMINOS Y CONDICIONES establecidos en este contrato. Continuarán aplicándose íntegramente todas las disposiciones aquí estipuladas, 
                            sin importar la modalidad de administración o gestión que el TITULAR elija. Nada en esta elección modificará 
                            los derechos y responsabilidades acordados entre el USUARIO y el TITULAR en estos TÉRMINOS Y CONDICIONES.<br/> <br/>
                        </Typography>
                    

                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                            SEGUNDA. USUARIO Y UTILIZACIÓN DEL SITIO WEB.<br/><br/>
                        </Typography>
                        
                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                            El acceso o utilización del SITIO WEB, así como de los recursos 
                            habilitados para interactuar entre los USUARIOS, o entre el USUARIO y el TITULAR tales como medios para realizar 
                            publicaciones o comentarios, confiere la condición de USUARIO del SITIO WEB, por lo que quedará sujeto a los 
                            presentes TÉRMINOS Y CONDICIONES, así como a sus ulteriores modificaciones, sin perjuicio de la aplicación de la 
                            legislación aplicable, por tanto, se tendrán por aceptados desde el momento en el que se accede al SITIO WEB. 
                            Dada la relevancia de lo anterior, se recomienda al USUARIO revisar las actualizaciones que se realicen a los 
                            presentes TÉRMINOS Y CONDICIONES.<br/><br/>
                            
                            El USUARIO asume la responsabilidad de utilizar el SITIO WEB de acuerdo con 
                            su diseño y finalidad establecida. Queda estrictamente prohibido utilizar cualquier tipo de software que 
                            automatice la interacción o descarga de los contenidos o servicios proporcionados a través del SITIO WEB. 
                            Asimismo, el USUARIO se compromete a utilizar la información, contenidos o servicios ofrecidos por el SITIO WEB 
                            de manera lícita, respetando plenamente los presentes TÉRMINOS Y CONDICIONES, así como los principios de moral y 
                            orden público. El USUARIO se abstendrá de realizar cualquier acto que pueda afectar los derechos de terceros o 
                            perjudicar el correcto funcionamiento del SITIO WEB.<br/><br/>
                            
                            El TITULAR conserva el derecho de eliminar aquellos comentarios y contribuciones que infrinjan la ley, atenten contra la dignidad de las personas, sean discriminatorios, 
                            afecten los derechos de terceros, contravengan el orden público o, a su criterio, no sean adecuados para su 
                            publicación.<br/><br/>
                            
                            En cualquier circunstancia, el TITULAR no será responsable de las opiniones expresadas por los USUARIOS mediante comentarios o publicaciones realizadas por estos últimos.<br/><br/>
                            
                            El acceso al SITIO WEB no implica el establecimiento de ningún tipo de relación entre el TITULAR y el USUARIO.<br/><br/>
                        </Typography>

                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        
                        TERCERA. ACCESO Y NAVEGACIÓN EN EL SITIO WEB.<br/><br/>
                        </Typography>
                        
                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        El TITULAR no puede garantizar de manera absoluta la continuidad y disponibilidad de los contenidos, 
                        productos o servicios ofrecidos a través del SITIO WEB. No obstante, el TITULAR se esforzará en la medida de lo 
                        posible para mantener un funcionamiento adecuado del SITIO WEB, aunque esto no implicará responsabilidad alguna por parte del TITULAR.<br/>
                        
                        
                        Asimismo, el TITULAR no asume responsabilidad ni garantiza que el contenido o software al 
                        que se accede a través del SITIO WEB esté libre de errores.<br/>
                        
                        Es importante destacar que el USUARIO es responsable de utilizar el SITIO WEB de manera 
                        adecuada y cumpliendo con los presentes TÉRMINOS Y CONDICIONES, así como con la normativa vigente. Cualquier uso 
                        indebido o en contravención de lo establecido en dichos términos será responsabilidad exclusiva del USUARIO. 
                        El TITULAR no será responsable por pérdidas, daños o perjuicios de cualquier tipo que puedan surgir como resultado 
                        del acceso o utilización del SITIO WEB. El USUARIO asume toda la responsabilidad por cualquier consecuencia derivada 
                        de su interacción con el SITIO WEB y su contenido.<br/> <br/>
                        </Typography>

                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        CUARTA. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS. <br/> <br/>

                        </Typography>



                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>

                        En estricto cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de Particulares en materia 
                        de protección de datos personales, el TITULAR asume el compromiso de implementar las medidas necesarias para asegurar 
                        la privacidad y seguridad de la información recabada, evitando su alteración, pérdida o uso no autorizado. 
                        Conforme a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, 
                        todo dato personal recolectado a través del SITIO WEB será tratado con estricto apego a los principios de licitud, 
                        calidad, finalidad, lealtad y responsabilidad. El tratamiento de dichos datos estará sujeto al consentimiento 
                        previo de su titular. En particular, el uso de datos financieros o patrimoniales requerirá de una autorización 
                        expresa por parte de los titulares, la cual podrá otorgarse a través de los mecanismos dispuestos en el SITIO WEB, 
                        asegurando en todo momento el cuidado y diligencia adecuados para proteger dicha información. Asimismo, 
                        lo mismo aplicará para el manejo de datos personales sensibles, entendiendo como tales aquellos que, 
                        en caso de un uso indebido, puedan ocasionar discriminación o representar un riesgo para el titular. 
                        En todo momento, se garantizará que los datos personales almacenados en las bases de datos o archivos 
                        pertinentes sean precisos, actualizados y adecuados para los fines específicos para los cuales fueron 
                        recopilados.<br/>
                        
                        La utilización de datos personales se restringirá a los propósitos establecidos en el Aviso de Privacidad, 
                        el cual estará accesible en la dirección electrónica siguiente: https://www.csemoguel.com/privacy<br/>
                        
                        El SITIO WEB podría contener hipervínculos o enlaces que redirijan a páginas web de terceros que no pertenecen al TITULAR. 
                        Dichos sitios web cuentan con sus propias políticas de privacidad y protección de datos, por lo tanto, 
                        el TITULAR no se hace responsable por la información que el USUARIO proporcione en sitios web diferentes al SITIO WEB. 
                        El TITULAR se reserva el derecho de modificar su Política de Privacidad según sea necesario o debido a cambios en la legislación. 
                        Al acceder o utilizar el SITIO WEB después de dichas modificaciones, el USUARIO acepta los cambios realizados. 
                        Asimismo, el acceso al SITIO WEB puede implicar el uso de cookies, que son pequeñas cantidades de información 
                        almacenadas en el navegador del USUARIO. Estas cookies facilitan la navegación y mejoran la experiencia del 
                        usuario sin dañar el dispositivo de navegación. Pueden recopilar información sobre el acceso al SITIO WEB, 
                        las preferencias del USUARIO y su interacción con el mismo, como la fecha y hora del acceso, el tiempo de uso, 
                        los sitios visitados antes y después, el número de páginas visitadas, la dirección IP del usuario, la frecuencia de visitas, etc. 
                        Esta información se utiliza para mejorar el SITIO WEB, detectar errores y satisfacer las necesidades del USUARIO, 
                        todo con el objetivo de ofrecer servicios y contenidos de mejor calidad. En todo caso, la información recopilada es anónima y 
                        no se identifica a usuarios individuales.<br/>
                        
                        Si el USUARIO no desea que se recopile este tipo de información, puede deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de internet. 
                        Cada navegador puede tener procedimientos distintos para llevar a cabo estas acciones, por lo que se recomienda revisar las instrucciones 
                        proporcionadas por el desarrollador del navegador. Si el USUARIO decide rechazar el uso de cookies (total o parcialmente), 
                        podrá seguir utilizando el SITIO WEB, aunque algunas funciones podrían quedar deshabilitadas. 
                        Es posible que en el futuro estas políticas con respecto a las cookies cambien o se actualicen, por lo que se recomienda revisar 
                        las actualizaciones a los presentes TÉRMINOS Y CONDICIONES para estar adecuadamente informado sobre el uso de cookies al ingresar o 
                        utilizar el SITIO WEB.<br/> <br/>
                        </Typography>

                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        QUINTA. ENLACES Y CONTENIDOS EXTERNOS DEL SITIO WEB.<br/> <br/>

                        </Typography>

                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        El SITIO WEB puede incluir enlaces, contenidos, servicios o funciones de otros sitios de internet que sean propiedad y/o gestionados por terceros, tales como imágenes, videos, comentarios, motores 
                        de búsqueda, entre otros. La utilización de estos enlaces, contenidos, servicios o funciones, tiene por objeto mejorar la experiencia del 
                        USUARIO al hacer uso del SITIO WEB, sin que pueda considerarse una sugerencia, recomendación o invitación para hacer uso de sitios externos. 
                        El TITULAR en ningún caso revisará o controlará el contenido de los sitios externos, de igual forma, no hace propios los productos, servicios, 
                        contenidos, y cualquier otro material existente en los referidos sitios enlazados; por lo cual, tampoco se garantizará la disponibilidad, 
                        exactitud, veracidad, validez o legalidad de los sitios externos a los que se pueda tener acceso a través del SITIO WEB. 
                        Así mismo, el TITULAR no asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso o uso, de los contenidos, 
                        productos o servicios disponibles en los sitios web no gestionados por el TITULAR a los que se pueda acceder mediante el SITIO WEB. 
                        Los USUARIOS o terceros que deseen realizar o publicar un enlace web desde una página web externa a este SITIO WEB deben tener en cuenta lo siguiente:
                        Queda prohibida la reproducción total o parcial de los contenidos, productos o servicios disponibles en el SITIO WEB 
                        sin la autorización expresa del TITULAR o su titular. Asimismo, no se permitirán manifestaciones falsas, 
                        inexactas o incorrectas sobre el SITIO WEB, sus contenidos, productos o servicios, y el TITULAR se reserva el derecho 
                        de restringir el acceso a aquellas personas que incurran en este tipo de actos.<br/>
                        
                        El establecimiento de un enlace al SITIO WEB desde cualquier 
                        sitio externo no implicará la existencia de una relación entre el TITULAR y el titular del sitio web desde el cual se realiza el enlace. Además, 
                        esto no implicará el conocimiento por parte del TITULAR de los contenidos, productos o servicios ofrecidos en los sitios externos 
                        desde los cuales se puede acceder al SITIO WEB.<br/> <br/>
                        
                        </Typography>


                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        SEXTA. PROTECCIÓN DE DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL.<br/> <br/>
                        </Typography>

                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                            El SITIO WEB, incluyendo su código fuente, imágenes, archivos de audio o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y 
                            otros elementos distintivos, son propiedad del TITULAR o han sido debidamente cedidos a su favor, quedando amparados 
                            por la legislación mexicana e internacional en materia de propiedad intelectual e industrial. En consecuencia, 
                            queda estrictamente prohibida la reproducción, distribución o difusión de los contenidos del SITIO WEB con fines comerciales, 
                            en cualquier formato o por cualquier medio, sin la previa autorización expresa del TITULAR. El USUARIO se compromete a respetar los derechos de propiedad intelectual e industrial del TITULAR. No obstante, 
                            se le permite visualizar los elementos del SITIO WEB, y podrá imprimirlos, copiarlos o almacenarlos exclusivamente para su uso personal. 
                            Asimismo, el USUARIO se obliga a no suprimir, alterar o manipular ningún elemento, archivo o contenido del SITIO WEB. De igual manera, 
                            se abstendrá de llevar a cabo acciones que pongan en riesgo la seguridad de los archivos o bases de datos protegidos, 
                            ya sea mediante acceso restringido con usuario y contraseña o por falta de permisos adecuados. 
                            Si el USUARIO o algún tercero considera que algún contenido del SITIO WEB vulnera sus derechos 
                            de propiedad industrial o intelectual, deberá comunicarlo de manera inmediata al TITULAR utilizando 
                            los datos de contacto disponibles en el propio SITIO WEB y/o mediante los siguientes medios de comunicación: 
                            Teléfono: 2214424621, Correo electrónico: ventas@csemoguel.com<br/> <br/>
                        </Typography>

                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        SÉPTIMA. EXENCIÓN DE RESPONSABILIDAD POR ERRORES E INEXACTITUDES. <br/> <br/>
                        </Typography>


                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        El USUARIO reconoce y acepta que el SITIO WEB y los servicios ofrecidos ocasionalmente pueden contener errores tipográficos, 
                        inexactitudes u omisiones en relación a las descripciones de productos, precios, promociones, ofertas, cargos por envío de productos, 
                        tiempos de tránsito y disponibilidad.<br/>
                        
                        El TITULAR del SITIO WEB se reserva el derecho de corregir cualquier error, 
                        inexactitud u omisión sin previo aviso, así como de modificar o actualizar la información en el servicio o en cualquier 
                        página web relacionada, en caso de detectar información inexacta en el servicio o en alguna página web relacionada. 
                        El USUARIO comprende que el TITULAR no está obligado a actualizar, modificar o aclarar la información en el servicio 
                        o en cualquier sitio web relacionado, incluyendo, pero no limitándose a, la información de precios, excepto cuando lo exija la ley.
                        Cualquier fecha de actualización indicada en el servicio o en cualquier sitio web relacionado no debe interpretarse como una 
                        indicación de que toda la información en el servicio o en cualquier sitio web relacionado ha sido modificada o actualizada.  <br/> <br/>
                        </Typography>


                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        OCTAVA. PROHIBICIONES DE USO DEL SITIO WEB.<br/> <br/>
                        </Typography>
                        

                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        El USUARIO reconoce y acepta que está estrictamente prohibido utilizar el sitio 
                        web y su contenido para los siguientes fines, en adición a las restricciones establecidas en los TÉRMINOS Y CONDICIONES:
                        a) Infringir cualquier reglamento, norma, ley u ordenanza local, internacional, federal, provincial o estatal.b) Solicitar a 
                        otros que realicen o participen en cualquier acto ilegal.c) Violentar o vulnerar nuestros derechos de propiedad intelectual o 
                        los derechos de propiedad intelectual de terceros.d) Cualquier propósito ilegal.e) Realizar actos de acoso, abuso, insulto, 
                        daño, difamación, calumnia, denigración, intimidación o discriminación por motivos de género, orientación sexual, religión, 
                        etnia, raza, edad, nacionalidad o discapacidad.f) Enviar información falsa o engañosa.g) 
                        Cargar o transmitir virus u otro tipo de código dañino que afecte o pueda afectar el funcionamiento del servicio, 
                        el sitio web o cualquier otro sitio relacionado, así como de otros sitios web o de Internet.h) 
                        Recopilar o rastrear información personal de terceros sin su consentimiento.i) 
                        Utilizar el servicio con fines obscenos o inmorales.j) Interferir o eludir las medidas de seguridad del servicio, 
                        del sitio web o de otros sitios web o de Internet.k) Enviar correos no deseados o llevar a cabo prácticas como el phishing, 
                        pharming, pretexting, spider, tracking o extracción de información.<br/>
                        
                        El USUARIO comprende y acepta que, en caso de contravenir cualquiera de las prohibiciones mencionadas, nos reservamos el derecho de dar por terminado 
                        su uso del servicio o de cualquier sitio web relacionado sin previo aviso.<br/> <br/>
                        </Typography>

                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        NOVENA. LIMITACIÓN DE RESPONSABILIDAD. <br/> <br/>
                        </Typography>


                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        El TITULAR y, en caso de que aplique, junto con sus directores, funcionarios, empleados, afiliados, agentes, contratistas, 
                        pasantes, proveedores, proveedores de servicios o licenciantes, no asumirá responsabilidad alguna por lesiones, pérdidas, 
                        reclamaciones o daños, sean directos, indirectos, incidentales, punitivos, especiales o consecuentes de cualquier índole. 
                        Esta exención de responsabilidad incluye, pero no se limita a, la pérdida de beneficios, ingresos, ahorros, datos, 
                        costos de reemplazo u otros daños similares, sin importar la teoría legal en la que se sustenten, ya sea contractual, 
                        extracontractual (incluida la negligencia), responsabilidad objetiva u otra.<br/>
                        
                        
                        En relación con el uso de los servicios o 
                        productos adquiridos a través del SITIO WEB, es importante destacar que responsabilidad del TITULAR no se limita únicamente a dicho uso. 
                        También se extiende a cualquier otro reclamo relacionado con el servicio o cualquier producto ofrecido. 
                        Esto incluye, entre otros, cualquier error u omisión en cualquier contenido, así como cualquier pérdida o daño de cualquier tipo que el USUARIO 
                        pueda experimentar debido al uso de nuestro servicio o al acceso a cualquier contenido (o producto) publicado, transmitido o puesto a disposición 
                        a través del mismo, incluso si se ha advertido sobre la posibilidad de dichos daños.
                        
                        Es relevante tener en cuenta que en ciertos estados o jurisdicciones, puede no ser viable excluir o limitar la responsabilidad por daños consecuentes
                        o incidentales. En tales casos, la responsabilidad estará limitada en la medida máxima permitida por la ley aplicable en esa jurisdicción particular. 
                        Sin embargo, el TITULAR se compromete a brindar un servicio de calidad y esta siempre dispuesto a atender cualquier inquietud o pregunta que pueda 
                        tener el USUARIO acerca de los servicios y productos.<br/> <br/>
                        </Typography>

                        
                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        DÉCIMA. INDEMNIZACIÓN. <br/> <br/>
                        </Typography>

                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        El USUARIO acepta y se compromete a mantener indemne al TITULAR y, en caso de aplicar, a su empresa matriz, subsidiarias, afiliadas, asociados, 
                        funcionarios, directores, agentes, contratistas, licenciantes, proveedores de servicios, subcontratistas, proveedores, pasantes y empleados, 
                        frente a cualquier reclamación o demanda presentada por terceros, incluidos los honorarios razonables de abogados, derivados de su incumplimiento 
                        de estos TÉRMINOS Y CONDICIONES o de cualquier otro documento incorporado por referencia, así como por la violación de leyes o derechos de terceros. <br/> <br/>
                       </Typography>
                       

                       <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        DÉCIMO PRIMERA. LEGISLACIÓN Y JURISDICCIÓN APLICABLE. <br/> <br/>
                        </Typography>
                       
                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        El TITULAR se reserva el derecho de ejercer las acciones civiles o penales que considere necesarias en caso de un uso indebido del SITIO WEB, sus contenidos, 
                        productos o servicios, o en caso de incumplimiento de los presentes TÉRMINOS Y CONDICIONES. 
                        La relación entre el USUARIO y el TITULAR estará sujeta a la legislación vigente en México, en particular en la Ciudad de México. En caso de surgir alguna 
                        controversia respecto a la interpretación y/o aplicación de los presentes TÉRMINOS Y CONDICIONES, ambas partes se someterán a la jurisdicción ordinaria 
                        de los tribunales correspondientes según lo establecido en la legislación del estado mencionado. 
                        DÉCIMO SEGUNDA. NULIDAD E INEFICACIA DE LAS CLÁUSULAS. En caso de que alguna cláusula incluida en los presentes TÉRMINOS Y CONDICIONES sea declarada, 
                        total o parcialmente, nula o ineficaz, dicha declaración de nulidad o ineficacia afectará únicamente a esa disposición o a la parte de la misma que 
                        resulte afectada, manteniéndose vigentes los demás TÉRMINOS Y CONDICIONES. La cláusula afectada se considerará como no escrita. <br/> <br/>
                        </Typography>

                        <Typography variant="home-description-negrita" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        DÉCIMO TERCERA. CONTACTO.<br/> <br/>
                        </Typography>
                        <Typography variant="home-description-black" align="left"component="p"  sx={{ whiteSpace: 'pre-line'}}>
                        Si el USUARIO tiene alguna pregunta acerca de los TÉRMINOS Y CONDICIONES o desea realizar algún comentario sobre el 
                        SITIO WEB, podrá comunicarse con nosotros mediante el correo electrónico proporcionado en el SITIO WEB. Estaremos encantados de responder a sus 
                        inquietudes y comentarios.Una vez leídos estos TÉRMINOS Y CONDICIONES, el USUARIO habrá sido informado acerca de sus implicaciones y las 
                        responsabilidades que le conciernen. Si el USUARIO no está de acuerdo con estos términos, deberá abstenerse de utilizar el SITIO WEB. 
                        Es importante destacar que estos TÉRMINOS Y CONDICIONES están sujetos a cambios y actualizaciones, por lo que se recomienda revisarlos 
                        periódicamente en el SITIO WEB.
                        Última actualización: 29 de febrero de 2024  
                        </Typography>
                    </Grid>
                </Grid>
            
        </Container>
    );
}