import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { cyan,grey } from '@mui/material/colors';
import Main from './views/main/Main';
import Home from './views/home/Home';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import ServiceDetail from './views/service_detail/ServiceDetail';
import Privacy from './views/privacy/privacy';
import Terms from './views/terms/Terms';


const defaultTheme = createTheme({
  palette: {
    primary: {
        main: '#ffffff',
    },
  },

  typography: {
      allVariants: {
        fontFamily: "'Montserrat', sans-serif",
          color: 'white'
      },
  },
  
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#E0E3E7',
          '--TextField-brandBorderHoverColor': '#B2BAC2',
          '--TextField-brandBorderFocusedColor': '#6F7E8C',
          '& label.Mui-focused': {
            color: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
        input: {
            color: 'white',
            '&::before': 
                "border-bottom: 1px solid rgba(0, 0, 0, 0.42)"
            
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--TextField-brandBorderColor)',
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderHoverColor)',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: "green",
          borderRadius: 20,
          position: "relative",
          zIndex: 0,
          transition: "transform 0.15s ease-in-out",
          "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
          boxShadow: "0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 6px 40px 0 rgba(0, 0, 0, 0.1)",
        },
      },
    },

    MuiList:{
      styleOverrides: {
        root: {
          props: { variant: 'valores' },
        
        },
      },
      variants: [
        {
          props: { variant: 'valores' },
          
        }
      ]
    },

    MuiContainer:{
      variants: [
        {
          props: { variant: 'no-color' },
          style: {
            maxWidth:false,
            backgroundColor: grey[200],
            padding:56
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            maxWidth:false,
            backgroundColor: "#0A2842",
          },
        },
        {
          props: { variant: 'contained-1' },
          style: {
            maxWidth:false,
            background: 'linear-gradient(to right bottom, #252525, #252525)',
          },
        },

        {
          props: { variant: 'contained-2' },
          style: {
            background: '#ff3e00',
            backgroundImage:  "url('https://firebasestorage.googleapis.com/v0/b/demos-eb2b9.appspot.com/o/comercio_internacional.jpg?alt=media&token=b1a5007d-09b7-4f5e-aeba-c593d437ea46')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          },
        },
        {
          props: { variant: 'contained-3' },
          style: {
            backgroundColor: "#0A2842",
            paddingBottom:20
          },
        },

        {
          props: { variant: 'footer' },
          style: {
            background: '#252525',
          },
        },

      ]
    },

    MuiButton: {
      variants: [
        {
          props: { variant: 'services' },
          style: {
            textTransform: 'lowercase',
            fontSize:16,
            border: `0px solid #e5e5e5`,
            color:"#b6b6b6",
            fontStyle:"normal",
            marginBottom:32,
            borderRadius: 8,
         
          },
        },
        {
          props: { variant: 'vermas' },
          style: {
            textTransform: 'uppercase',
            fontSize:16,
            border: `1px solid #FFF`,
            fontStyle:"normal",
            marginBottom:32,
            borderRadius: 56,
            fontWeight:"bold"
          },
        },
        {
          props: { variant: 'appbar' },
          style: {
            textTransform: 'none',
            border: `1px solid ${cyan[50]}`,
            fontStyle:"normal",
            borderRadius: 56,
            fontWeight:"bold"
          },
        },
        {
          props: { variant: 'header' },
          style: {
            fontStyle:"normal",
            borderRadius: 56,
            textTransform: 'none',
            fontWeight:"bold"
          },
        },
      ],
    },

    MuiTypography:{
      variants: [
        {
          props: { variant: 'home-title' },

          style: {
            fontSize:56,
            fontFamily: [
              "Bebas Neue", "sans-serif"
            ].join(','),
          },
        },
        {
          props: { variant: 'home-subtitle' },
          style: {
            fontWeight:"bolder",
            color:"#FFF",
            fontSize:"56px",
          },
        },
        {
          props: { variant: 'home-subtitle-black' },
          style: {
            
            fontWeight:"bolder",
            color:"#252525",
            fontSize:"56px",
            
          },
        },
        {
          props: { variant: 'home-header' },
          style: {
            
            fontWeight:"bolder",
            color:"#FFF",
            fontSize:"32px",
          
            align:"center",
          },
        },
        {
          props: { variant: 'home-header-black' },
          style: {   
            fontWeight:"bolder",
            fontSize:"32px",
            align:"center",
            color:"#252525"
          },
        },
        {
          props: { variant: 'home-description' },
          style: {
            textTransform: 'none',
            color:"#FFF",
            fontSize:"24px",
            textAlign: 'center'
          },
        },
        {
          props: { variant: 'home-description-negrita' },
          style: {
            textTransform: 'none',
            color:"#252525",
            fontSize:"18px",
            fontWeight:"bolder",
          },
        },
        {
          props: { variant: 'home-description-2' },
          style: {
            textTransform: 'none',
            color:"#FFF",
            fontSize:"18px",
            textAlign: 'center'
          },
        },
        {
          props: { variant: 'home-description-black' },
          style: {
            textTransform: 'none',
            color:"#252525",
            fontSize:"18px",
          },
        },
        {
          props: { variant: 'home-description-1' },
          style: {
            textTransform: 'none',
            color:"#000",
            fontSize:"24px",
            background:'#fff',
            padding:"18px",
            borderRadius:"20px"
          },
        },
        
      ]
    },

    MuiTextField:{
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'none',
            fontStyle:"normal",
            color:"#FFF",
          },
        },
      ]
    }
  },
});


function App() {
  return (
    <>
    <ThemeProvider theme={defaultTheme}>
    <BrowserRouter>
      <Routes>
        <Route path="/service-detail" element={<Main props={<ServiceDetail/>}></Main>} />
        <Route path="/home" element={<Main props={<Home/>}></Main>} />
        <Route path="/" element={<Main props={<Home/>}></Main>} />
        <Route path="/contact-us" element={<Main props={<></>}></Main>} />
        <Route path="/privacy" element={<Main props={<Privacy/>}></Main>} />
        <Route path="/terms" element={<Main props={<Terms/>}></Main>} />
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
    </>
  );
}

export default App;
