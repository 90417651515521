import { Container, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import VizSensor from 'react-visibility-sensor';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const services=[
  {
    title: 'HARDWARE, SOFTWARE y SERVICIOS INFORMATICOS.',
    description:  "Te ofrecemos distintas alternativas de software y hardware que se adapten a "+
    "las necesidades de tu empresa, maximizando la operatividad del dia a dia.\n"+
    "Contamos con el respaldo de cada una de las marcas que comercializamos, "+ 
    "lo cual te garantiza que el producto ofertado es el indicado para ti.\n"+
    "Nuestro personal cuenta con las certificaciones necesarias para poder apoyarte "+ 
    "y asesorarte en el dimensiónamiento de lo que desees ejecutar.\n"+
    "Trabajamos constantemente para poder cumplir con los plazos y tiempos de entrega requeridos por nuestros clientes.",
    features:[ 
      "COMERCIALIZACIÓN DE QUIPO DE COMPUTO",
      "SERVIDORES",
      "DIGITALIZACIÓN",
      "VIDEOPROYECCIÓN",
      "LICENCIAMIENTO",
      "CENTRO DE DATOS",
      "CONMUTADORES EN SITIO Y NUBE",
      "DESARROLLO DE SOFTWARE Y TECNOLOGIA",
      "DESARROLLO DE REALIDAD VIRTUAL, REALIDAD AUMENTADA",
      "DESARROLLO DE VIDEO JUEGOS, Y SIMULADORES",
      "RECORRIDOS VIRTUALES Y MODELADO 3D Y RENDERING",
      "ERP PARA EMPRESAS",
      "CONTROL DE ACCESOS",
      "ENERGIA EN ALTA, BAJA Y MEDIA TENSIÓN"],

      features1:[ 
      "Sistemas web responsivos",
      "MicroServices con AZURE, AWS, Google cloud.",
      "Bases de datos: RDS, NO-SQL.",
      "Sistemas de Seguridad",
      "Sistemas e-commerce AAA",
      "Sistemas embebidos",
      "Aplicaciones móviles iOS y Android",
      "MVVM, MVM, Serverless"],
      url_background:"https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/servicios_informaticos_background.png?alt=media&token=5d72ac43-dfc7-46e3-b806-fc781d3f3981"

  },
  {
    title: 'PAPELERIA, CONSUMIBLES, IMPRESIÓN Y ALMACENAMIENTO',
    description:  "Contamos con  todos los artículos necesarios, que faciliten tu desempeño en el espacio laboral que te encuentres.",
    subtitle1:"PAPELERIA",
    subtitle2:"IMPRESIÓN Y MULTIFUNCIONALES",
    subtitle3:"ALMACENAMIENTO",
    features:[ 
      "Articulos de Escritura ",
      "Cuadernos, Blocks y Libros",
      "Didacticos y de Dibujo",
      "Sujetadores y Portadocumentos  ",
      "Folders",
      "Organizadores y Cajas ",
      "Cintas y pegamentos",
      "Sellos y Foliadores",
      "Carpetas",
      "Pizarrones",
      "Articulos para cortar",
      "Etiquetadoras",
      "Engrapadoras y perforadores"],                        

    features1:[ 
      "Impresoras Laser",
      "Impresoras de Inyeccion",
      "Impresoras de matriz de punto",
      "Multifuncionales",
      "Impresoras POS",
      "Rotulador Electronico"],

    features2:[ 
      "CD",
      "DVD",
      "USB",
      "Cintas de Almacenamiento",
      "Blu-Ray"
    ],

    url_background:"https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/papeleria_background.png?alt=media&token=63105032-d291-4b0a-8fc2-d4f693c1d602"

  },
  {
    title: 'MOBILIARIO ESCOLAR Y DE OFICINA ',
    description:  "Tenemos una gran variedad de alternativas que garantizan el confort y postura adecuada cuando ejecutas tus actividades laborales o educativas.",
    
    subtitle1:"ESCOLAR",
    subtitle2:"OFICINA",
    
    features:[ 
      "Anaqueles",                                 
      "Archiveros",                                       
      "Bancos",                                                
      "Gabinetes",                                           
      "Lockers",                                             
      "Mesabancos",                              
      "Mesas de laboratorio",                       
      "Mesas para primaria y preescolar",    
      "Mesas trapezoides",
      "Pupitres"],
    features1:[ 
        "Sillas Ejecutivas",
        "Sillas de Visita",
        "Escritorios",
        "Sillas de recepción",
        "Mesas para sala de juntas",
        "Mesas para cafeteria",
      "Archiveros y libreros"
    ],
        url_background:"https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/mobiliario_background.png?alt=media&token=1a0baf69-a369-4e5c-a1f4-28fbac3d33d7"
  },
  {
    title: 'SISTEMAS DE SEGURIDAD PERIMETRAL',
    description:  "Ofrecemos soluciones básicas de protección perimetral para detectar posibles riesgos de intrusión.",
    features:[ 
      "VIDEOVIGILANCIA",
        "CONTROL DE ACCESO",
        "SISTEMAS DE ALARMAS ELECTRONICAS",
        "SISTEMAS DE SEGURIDAD CIRCUITO CERRADO"],
      features1:[ ],
    url_background:"https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/main_video_security.png?alt=media&token=784e2cd7-12c9-4e99-89f0-672685dd9b88"
    
  },
  {
    title: 'Comercio Internacional',
    description:  "Nos encargamos de la importación de cualquier producto con las "+
    "especificaciones que requieren nuestros clientes, bajo la normatividad y documentación "+
    "requerida, gestionando los tramites necesarios para que estos lleguen en tiempo indicado. ",
    features:[ 
      "Logistica de transporte, Mercancia y Personal. Importación de "+
      "productos de cualquier tipo "+
      "gracias a nuestros socios comerciales en USA, China Y Europa"],
    features1:[ ],
    url_background:"https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/world_network.jpg?alt=media&token=1f98c4b0-d3a3-48a9-8781-586f0c4d7031"
    
  }
]

export default function ServiceDetail(){

  let query = useQuery()
  
  function getContent(){
    const numb=query.get("service")
    var service= services[0]
    switch(numb){
      case "1":{
        service= services[0]
      }break;
      case "2":{
        service= services[1]
      }break;
      case "3":{
        service= services[2]
      }break;
      case "4":{
        service= services[3]
      }break;
      case "5":{
        service= services[4]
      }break;
    }
    return service
  }

  return (
      <>
      {/* Brands  */}
      <VizSensor >     
        <Container variant="black" maxWidth={true} component="main" sx={{ pt: { xs: 8, sm: 8 },pb: { xs: 8, sm: 8 }, backgroundColor:"#000"}}>        
          <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12} >
                  <Typography variant="home-header"
                      align="center"
                      component="p">
                      {getContent().title}
                  </Typography>
              </Grid>

              
              
              <Grid item xs={12} md={12} sm={12}>
                  <Typography  align="center" component="p"  sx={{ whiteSpace: 'pre-line'}}>
                      {getContent().description}
                  </Typography>
              </Grid>

          </Grid>   
        </Container>
      </VizSensor>
      {/* Brands */}
      {/* Brands  */}
      <VizSensor >     
        <Container variant="black" maxWidth={true} component="main" sx={{ pt: { xs: 8, sm: 8 },pb: { xs: 8, sm: 8 }, backgroundColor:"#000"}}>        
          <Grid container spacing={2}>
              
              <Grid item xs={12} md={6} sm={6} >
                  <Typography variant="home-header"
                      align="left"
                      component="p">
                      {getContent().subtitle1}
                  </Typography>
                  <Typography style={{fontWeight:"bold"}}>
                   {getContent().features.map((feature) => (
                    <><li> {feature}</li><br/></>
                    ))}

                  </Typography>
              </Grid>

              <Grid item xs={12} md={6} sm={6} >
                  <Typography variant="home-header"
                      align="left"
                      component="p">
                      {getContent().subtitle2}
                  </Typography>
                  <Typography style={{fontWeight:"bold"}}>
                    {getContent().features1.map((feature) => (
                    <><li> {feature}</li><br/></>
                    ))}
                </Typography>
              </Grid>

              <Grid item xs={12} md={6} sm={6} >
                  <Typography variant="home-header"
                      align="left"
                      component="p">
                      {getContent().subtitle3}
                  </Typography>
                  <Typography style={{fontWeight:"bold"}}>
                  
                  {getContent().features2?.map((feature) => (
                  <><li> {feature}</li><br/></>
                  ))}
              </Typography>
              </Grid>
              <Grid item xs={12} md={6} sm={6} >
                  <Typography variant="home-header"
                      align="left"
                      component="p">
                      {getContent().subtitle4}
                  </Typography>
              </Grid>
          </Grid>   
          <Grid item xs={6} md={6} sm={6}/>
        </Container>
      </VizSensor>
      {/* Brands */}

      {/* Brands  */}
      <VizSensor >     
        <Container variant="black" maxWidth={false} component="main" sx={{ pt: { xs: 8, sm: 8 },pb: { xs: 8, sm: 8 }, backgroundSize: 'cover', backgroundImage:"url("+getContent().url_background+")"}}>    
          <div style={{height:"512px"}}>

          </div>
        </Container>
      </VizSensor>
      {/* Brands */}
      </>
  );
}