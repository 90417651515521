
import * as React from 'react';
import { useEffect,useRef, useState } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, CardActions, CardMedia, CssBaseline, Divider, Fade, ImageList, ImageListItem, List, ListItem, Stack, TextField} from '@mui/material';
import Slide from '@mui/material/Slide';
import Zoom from '@mui/material/Zoom';
import VizSensor from 'react-visibility-sensor';
import ParticleBackground from "./ParticleBackground";

export default function Home() {

  const [secion1, setVisibleSection1] = useState(false);
  const [secion2, setVisibleSection2] = useState(false);
  const [secion3, setVisibleSection3] = useState(false);
  const [secion4, setVisibleSection4] = useState(false);
  const [secion5, setVisibleSection5] = useState(false);
  const [secion6, setVisibleSection6] = useState(false);
  const [secion7, setVisibleSection7] = useState(false);
  const [secion8, setVisibleSection8] = useState(false);
  const [secion9, setVisibleSection9] = useState(false);
  const [secion10, setVisibleSection10] = useState(false);
  const [secion11, setVisibleSection11] = useState(false);

  const [brands, setBrands] = useState([
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Facteck.png?alt=media&token=acf3584d-3ab9-4ef8-a564-ae3e5223437a',
      title: 'acteck',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fadata.jpg?alt=media&token=e4bb4209-8f6c-4ea4-bbf5-fe7f00fc897c',
      title: 'adata',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fbic.png?alt=media&token=61253ba1-c241-43b1-9903-988d7960c5c7',
      title: 'bic',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fbrother.png?alt=media&token=0251b1ae-6116-451e-ad32-e8b3cd1f9f22',
      title: 'brotther',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fcanon.jpg?alt=media&token=c9fe2dce-862f-44be-afdb-1b795023712c',
      title: 'canon',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fdixon.jpg?alt=media&token=2be6d759-4b9c-412a-8e2d-44549751ad00',
      title: 'dixon',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fepson.jpg?alt=media&token=ccabc00b-5850-4ee7-a730-fb3aa0286f4b',
      title: 'epson',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fescribe.png?alt=media&token=12f53580-3370-4597-8d0a-8285ecac6f26',
      title: 'escribe',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Festrella.png?alt=media&token=f606d9a3-375f-40de-9531-60951a38e652',
      title: 'estrella',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Ffabercastell.png?alt=media&token=191fcd21-c933-4291-9b08-42757e3261f0',
      title: 'faber-castell',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fgenius.jpg?alt=media&token=f2cd7e21-0636-4a95-9acd-754b05e63ca8',
      title: 'genius',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fhenkel.png?alt=media&token=3073e5b2-b8f0-4581-bcb2-c10321fe3747',
      title: 'henkel',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fhp.jpg?alt=media&token=34411277-f7bc-4ea4-9eb8-6ccc39d5b1c1',
      title: 'hp',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fkington.jpg?alt=media&token=5a2ce2d5-a08d-45e2-b708-b6d6ca5c98cc',
      title: 'kingston',
    },
    {
      img: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Flexmaark.jpg?alt=media&token=d45a4fd1-881a-427b-bc89-72286ad0c776',
      title: 'lexmark',
    },
    {
      img:'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fnorma.png?alt=media&token=72c743a4-ff58-4094-b63f-38a6915884b4',
      title:'norma'
    },
    {
      img:'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fpelikan.png?alt=media&token=dc59d0dd-efae-4a15-ab1c-35c8c0019b48',
      title:'pelikan'
    },
    {
      img:'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fperfect_choice.jpg?alt=media&token=4578cc05-5b8d-4408-9b03-d19750e36e77',
      title:'perfectchoice'
    },
    {
      img:'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fxerox.jpg?alt=media&token=e16a0c4a-144d-4ee4-8863-052bfa13d01f',
      title:'xerox'
    },
    {
      img:'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fmaped.jpeg?alt=media&token=18bd1732-c1f5-4c08-a8ca-253fcabe8507',
      title:'maped'
    },
    {
      img:'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fbaco.png?alt=media&token=f63c4bc1-d938-4ebb-a4f7-07344f88c29e',
      title:'baco'
    },
    {
      img:'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/brands%2Fbarrilito.png?alt=media&token=37e7649f-551a-4db6-8ff7-78496f6d879c',
      title:"barrilito"
    }
  ])


  const [services, setServices] = useState([
    {
      title: 'HARDWARE, SOFTWARE y SERVICIOS INFORMATICOS.',
      image: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/servicios_informaticos.png?alt=media&token=478c7b72-e0bf-4d39-87d8-69d802e4af9c',
      isHover:false,
      elevation:20,
      href:"/service-detail?service=1"
    },
    {
      title: 'ARTICULOS DE PAPELERÍA Y CONSUMIBLES',
      image: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/papeleria_consumibles.png?alt=media&token=1e7f6a29-4707-42e0-a1b0-13ef1ef18e49',
      isHover:false,
      elevation:20,
      href:"/service-detail?service=2"
    },
    {
      title: 'MOBILIARIO ESCOLAR Y DE OFICINA ',
      image: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/mobiliario_officina_escolar.png?alt=media&token=9106cc0c-6962-4072-9cf2-b6a1f0ac47e1',
      isHover:false,
      elevation:20,
      href:"/service-detail?service=3"
    },
    {
      title: 'SISTEMAS DE SEGURIDAD PERIMETRAL',
      image: 'https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/sistemas_seguridad_perimetral.png?alt=media&token=3d60d4ab-14d8-4d93-8537-552cf015878c',
      isHover:false,
      elevation:20,
      href:"/service-detail?service=4"
    },
  ]);


  function handleSection(isVisible,seccion){
    switch(seccion){
      case 1:
        setVisibleSection1(isVisible);
      break
      case 2:
        setVisibleSection2(isVisible);
      break
      case 3:
        setVisibleSection3(isVisible);
      break
      case 4:
        setVisibleSection4(isVisible);
      break
      case 5:
        setVisibleSection5(isVisible);
      break
      case 6:
        setVisibleSection6(isVisible);
      break
      case 7:
        setVisibleSection7(isVisible);
      break
      case 8:
        setVisibleSection8(isVisible);
      break
      case 9:
        setVisibleSection9(isVisible);
      break
      case 10:
        setVisibleSection10(isVisible);
      break
      case 11:
        setVisibleSection11(isVisible);
      break
      default:
    }
  }

  useEffect(()=>{
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }
  },[])

  function addAnimation() {

    const scrollers = document.querySelectorAll(".scroller");
    scrollers.forEach((scroller) => {
      
      scroller.setAttribute("data-animated", true);

      const scrollerInner = scroller.querySelector(".scroller__inner");
      const scrollerContent = Array.from(scrollerInner.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute("aria-hidden", true);
        scrollerInner.appendChild(duplicatedItem);
      });
    });
  }

  return (
      <>
      {/* Titulo */}
      <VizSensor partialVisibility={true}  onChange={(isVisible) => {handleSection(isVisible,1)}} >   
     
      <div style={{position:"relative", background:"#0A2842"}}>
        <ParticleBackground style={
          {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            padding: 0,
            margin: 0,
            zIndex: 0,
          }
        }/>

        <div style={{
           position: "absolute", 
           bottom:"40%",
           left: 0,
           right: 0, 
           marginLeft: "auto", 
           marginRight: "auto", 
           zIndex: 1
        }}>

          <Stack
            justifyContent= "center"
          >
            <Typography variant='home-title' align='center' justifyContent={"center"}>
              Comercialización y servicios especializados moguel
            </Typography>
          </Stack> 
          </div>
        </div>
      </VizSensor>
      {/* Titulo */}
    
      {/* ¿Quienes somos?  */}
      <VizSensor partialVisibility={true}  onChange={(isVisible) => {handleSection(isVisible,2)}} >     
        <Container
          variant="contained"
          maxWidth={false}  
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 8, sm: 12 },
            pb: { xs: 8, sm: 12 },
            }}
        >
          <Stack useFlexGap sx={{ width: { xs: '100%', sm: '75%', md:"75%"} }}>
            <Typography
              variant="home-subtitle"
              align="center"
              component="p"
            >
            ¿Quienes somos?
            </Typography>
            <Zoom in={secion2} style={{ transitionDelay: secion2 ? '500ms' : '0ms' }}>
              <Typography variant="home-description" align="center" component="p">
                Empresa de profesionales con una amplia gama de productos y servicios, enfocados en satisfacer  
                las necesidades tecnológicas, de equipamiento e insumos para oficinas, 
                Pymes, sector educativo y de gobierno a nivel local y nacional.
              </Typography>
            </Zoom >
          </Stack>                
        </Container>
      </VizSensor>
      {/* ¿Quienes somos? */}

      {/* People  */}
      <VizSensor>     
        <Container
          variant="contained"
          maxWidth={false}  
          component="main"
          sx={{
            background:"url('https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/Screenshot%202024-02-22%20at%201.25.48%E2%80%AFp.m..png?alt=media&token=0b412012-84f6-4d6c-b1f7-6a3a25ea7473')",
            display: 'flex',
            backgroundSize: 'cover',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 32, sm: 32 },
            pb: { xs: 32, sm: 32 },
            }}
        >              
        </Container>
      </VizSensor>
      {/* People */}

      {/* Brands  */}
      <VizSensor>     
        <Container variant="white" maxWidth={false} component="main" sx={{pt: { xs: 8, sm: 8 },pb: { xs: 8, sm: 8 }}}>    
          <div class="scroller" data-direction="left" data-speed="fast" style={{width:"100%"}}>
            <div class="scroller__inner">
              {brands.map((item) => (
                  <img style={{
                  maxWidth: "200px",
                  height: "100px",
                  padding: 0,
                  margin: 0
                }}
                  srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                />
              ))}
            </div>
          </div>
        </Container>
      </VizSensor>
      {/* Brands */}

      {/* Servicios principales */}
      <Divider orientation="horizontal" variant="middle" flexItem />
      <VizSensor partialVisibility={true}  onChange={(isVisible) => {handleSection(isVisible,3)}} >
        <Container maxWidth={false}   variant="no-color" component="main">
          <Typography
            component="h2"
            variant="h2"
            align="center"
            gutterBotto
            color="#252525"
            marginBottom={"20px"}
          >
            Nuestros Servicios
          </Typography>
          <Fade in={secion3} style={{ transitionDelay: secion3 ? '800ms' : '0ms' }}>
            <Grid container spacing={5} align="center" justifyContent="center">  
              {services?.map((service) => (
                <Grid
                  item
                  key={service.title}
                  xs={12}
                  sm={6}
                  md={3}
                >
                  <Card elevation={service.elevation}>

                    <CardMedia
                        sx={{ height: 160 }}
                        image={service.image}
                        title={service.title}
                      />
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          mb: 2,
                        }}
                      >
                        <Typography 
                          variant="home-description-b"
                          align="center"
                          component="p">
                          {service.title}
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActions style={{justifyContent: 'center',paddingBottom:"0px"}}>
                      <Button variant='services' href={service.href}>
                        ver más
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}  
            </Grid>
          </Fade>
        </Container>
      </VizSensor>
      {/* Servicios principales */}
     
      {/* Misión, Visión y Valores */}
      <Divider orientation="horizontal" variant="middle" flexItem />
      <VizSensor partialVisibility={true}  onChange={(isVisible) => {handleSection(isVisible,4)}} >
        <Container maxWidth={false} disableGutters variant="no-color" omponent="main">
          <Typography
            component="h2"
            variant="h2"
            align="center"
            gutterBotto
            color="#252525"
            marginBottom={"20px"}
          >
            Misión, Visión y Valores
          </Typography>
        </Container>
      </VizSensor>
      {/* Misión, Visión y Valores */}

      {/* Nuestra Misíon */}
      <VizSensor partialVisibility={true}  onChange={(isVisible) => {handleSection(isVisible,5)}} >
        <Container maxWidth={false} disableGutters variant="no-color"  component="main"> 
        <Grid container spacing={3}>
          <Grid item xs={12}  sm= {6} md={6}  align="center">
          <Zoom in={secion5} style={{ transitionDelay: secion5 ? '500ms' : '0ms' }}>
            <Box
              component="iframe"
              frameBorder="0"
              sx={{
                height: 256,
                width: 256,
                maxHeight: { xs: 256, md: 256 },
                maxWidth: { xs: 256, md: 256 },
                minHeight: { xs: 256, md: 256 },
                minWidth: { xs: 256, md: 256 },
              }}
              alt="¿Que hacemos?"
              src="https://lottie.host/embed/9ec31926-e7b1-4513-a00d-84f249d48496/nHkLJbsenv.json"
            />
            
            </Zoom>
            
          </Grid>
        
          <Grid  item xs={12} sm= {6} md={6}  align="center">
            <Typography
              variant="home-subtitle-black"
              align="center"
              component="p"
            >
              Nuestra Misión
            </Typography>
            <Slide direction="right" in={secion5}  style={{ transitionDelay: secion5 ? '500ms' : '0ms' }}>
            <Typography variant="home-description-black" align="center" component="p">
            Distribuir productos de marcas reconocidas y proporcionar servicios informáticos que faciliten 
            a nuestros clientes la operatividad de sus negocios y empresas.
            </Typography>
            </Slide>
          </Grid>   
        </Grid>
        
        </Container>
      </VizSensor>
      {/* Nuestra Misíon */}

      {/* Nuestra Visión */}
      <Divider orientation="horizontal" variant="middle" flexItem />
      <VizSensor partialVisibility={true}  onChange={(isVisible) => {handleSection(isVisible,6)}} >
        <Container maxWidth={false} disableGutters variant="no-color"  component="main"> 
        <Grid container spacing={3}>
          <Grid  item xs={12}  sm= {6} md={6}  align="center"> 
            <Typography
              variant="home-subtitle-black"
              align="center"
              component="p"
            >
              Nuestra Visión
            </Typography>
            <Slide direction="right" in={secion6}  style={{ transitionDelay: secion6 ? '500ms' : '0ms' }}>
            <Typography variant="home-description-black" align="center" component="p">
            Permanecer dentro de los principales distribuidores a nivel nacional, 
            respaldados por personal especializado y comprometidos con brindar excelente atención y servicio de calidad, 
            durante, al finalizar y posterior al proceso de la transacción.
            </Typography>
            </Slide>
          </Grid>  
          <Grid item xs={12}  sm= {6} md={6} align="center">
            <Zoom in={secion6} style={{ transitionDelay: secion6 ? '500ms' : '0ms' }}>
            <Box
              component="iframe"
              frameBorder="0"
              sx={{
                height: 256,
                width: 256,
                maxHeight: { xs: 256, md: 256 },
                maxWidth: { xs: 256, md: 256 },
                minHeight: { xs: 256, md: 256 },
                minWidth: { xs: 256, md: 256 },
              }}
              alt="¿Que hacemos?"
           
              
              src="https://lottie.host/embed/b3377436-2409-416a-a0ee-538d47840cf8/2zjORSppew.json"
            />
            
            </Zoom>
          </Grid> 
        </Grid>
        
        </Container>
      </VizSensor>
      {/* Nuestra Visión */}

      {/* Nuestros valores */}
      <Divider orientation="horizontal" variant="middle" flexItem />
      <VizSensor partialVisibility={true}  onChange={(isVisible) => {handleSection(isVisible,7)}} >
        <Container maxWidth={false} disableGutters variant="no-color"  component="main" sx={
          {
            backgroundSize: 'cover',
            backgroundImage:"url('https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/values.png?alt=media&token=9838ee16-91d0-4594-9172-c96ae38963d3')"
          }
        }> 
        <Grid container spacing={3}>
          <Grid item  xs={12}  sm= {6} md={6} align="center"/>
          
        
          <Grid item  xs={12}  sm= {6} md={6} align="center">
          
            <Typography
              variant="home-subtitle-black"
              align="center"
              component="p"
              color="#FFF" 
            >
              Nuestros Valores
            </Typography>
            <Slide direction="right" in={secion7}  style={{ transitionDelay: secion7 ? '500ms' : '0ms' }}>
            <List variant = "valores">
                    {[" ■  Transparencia", " ■  Excelencia", " ■  Resolución"," ■  Responsabilidad"," ■  Lealtad"].map((value) =>
                    <>
                      <ListItem>
                        <Typography variant="home-description-black" color="#FFF" fontWeight={"600"} align="center" component="p">{value}</Typography>
                       
                      </ListItem>
                      <Divider  orientation="horizontal" variant="middle" flexItem ></Divider>
                      </>
                    )}
                  </List>
            </Slide>
          </Grid>   
        </Grid>
        
        </Container>
      </VizSensor>
      {/* Nuestra valores */}

      {/* Comercio Internacional */}
      <VizSensor  partialVisibility={true} onChange={(isVisible) => {handleSection(isVisible,9)}} >
      <Container maxWidth={false} variant="contained-3" component="main">
        <Typography
           component="h2"
           variant="h2"
           align="center"
           gutterBottom
           marginBottom={"20px"}
           paddingTop={"32px"}
           color={"#fff"}
        >
         Comercio Internacional
        </Typography>
        <Grid  container spacing={3}>
          <Grid item  xs={12}  sm= {12} md={12} align="center">
            
          </Grid>
          <Slide direction="right" in={secion9}  style={{ transitionDelay: secion9 ? '700ms' : '0ms' }}>
          <Grid item  xs={12}  sm= {12} md={12} align="center">
            <Box
                component="iframe"
                frameBorder="0"
                sx={{
                  Width: "auto",
                  maxHeight: { xs: 712, md: 712 },
                  maxWidth: { xs: 712, md: 712 },
                  minHeight: { xs: 332, md: 712 },
                  minWidth: { xs: 332, md: 712 },
                }}
                alt="¿Que hacemos?"    
                src="https://lottie.host/embed/9e6cbeae-3302-4ab0-b683-56125a29f7a6/YG3VL5Ak9B.json"
              />
          </Grid>
          </Slide>
          <Grid item  xs={12}  sm= {6} md={6} align="center"/>
          <Grid item  xs={12}  sm= {12} md={12} align="center">
            <Button variant='vermas' href={"/service-detail?service=5"}>ver más</Button>
          </Grid>
        </Grid>
        
        {/*<Divider color="#FFF" marginTop="20px" ></Divider>
        <Zoom in={secion9} style={{ transitionDelay: secion9 ? '700ms' : '0ms'}}>
        <Grid container spacing={5} style={{marginTop:"20px", marginBottom:"40px"}}>
          <Grid item  xs={12}  sm= {6} md={6} align="center">
            <Typography variant="home-description-2" color="#fff" component="p">
            Nos encargamos de la importación de cualquier producto con
            las especificaciones que requieren nuestros clientes, bajo la
            normatividad y documentación requerida.
            </Typography>
          </Grid>
          <Grid item  xs={12}  sm= {6} md={6} align="center">
          <Typography variant="home-description-2"  color="#fff" component="p">
          Logistica de transporte, mercancia y personal, gracias a nuestros socios comerciales en USA, China Y Europa.
          </Typography>
           
          </Grid>
        </Grid>
        </Zoom>*/}
      </Container>
      </VizSensor>
      {/* Comercio Internacional */}

      </>
  );
}