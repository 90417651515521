import { Container, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect,useRef, useState } from "react";
import VizSensor from 'react-visibility-sensor';

export default function Privacy() {

    return (
        <>
        {/* Brands  */}
        <VizSensor >     
            <Container variant="no-color"  component="main" sx={{ pt: { xs: 8, sm: 8 },pb: { xs: 8, sm: 8 }}}>    
                
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12} >
                    <Typography variant="home-header-black"
                        align="left"
                        component="p">
                        Politicas de privacidad 
                    </Typography>
                </Grid>
            
                <Grid item xs={12} md={12} sm={12} >
                    <Typography variant="home-description-black"
                    fontWeight={"bolder"}
                        align="left"
                        component="p">
                        Ultima actualización: Marzo 7, 2024
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-description-black"
                        align="left"
                        component="p">
                        Con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en lo sucesivo la “Ley”), su Reglamento y demás disposiciones aplicables en México.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-description-black"
                        align="left"
                        component="p">

                        Hacemos de tu conocimiento que la información aquí solicitada, será utilizada únicamente con los siguientes fines:
                        </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-description-black"
                        align="left"
                        component="p">
                            <li>
                            Brindar información a toda aquella persona interesada en la adquisición de nuestros productos y servicios.
                            </li><br/>
                            <li>
                            Análisis estadístico y registro histórico de navegación en la página web de CSE.  </li><br/>
                            <li>
                            Análisis de datos mediante el uso de tecnologías de analítica con el objeto de evaluar el uso los servicios e información proporcionada en la pagina web.</li><br/>
                            <li>
                            Atención a través de medios electrónicos o de redes sociales.</li><br/>
                            <li>
                            Encuestas de opinión y satisfacción con la atención brindada en nuestro servicio, para una mejora integral que vaya encaminada al cumplimiento de nuestros valores comerciales.</li><br/>
                        </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-description-black"
                        align="left"
                        component="p">
                             La información personal que es recabada a través de nuestro portal, son datos de contacto, datos de identificación, navegación, geolocalización y de dispositivos.
                        </Typography>
                </Grid>


                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-header-black"
                        align="left"
                        component="p">
                           ¿Cómo es utilizada tu información?
                        </Typography>
                </Grid>

                
                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-description-black"
                        align="left"
                        component="p">
                           Toda la información proporcionada en la pagina web de CSE, es utilizada para tener un mayor entendimiento 
                           de las necesidades expresadas por nuestros clientes  y poder ofrecer un amplia  gama de soluciones que vayan acorde a sus presupuestos y plazos de ejecución requeridos.
                        </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-description-black"
                        align="left"
                        component="p">
                           Así mismo, proporcionar un adecuado dimensionamiento en los proyectos a 
                           desarrollar y un seguimiento eficiente por el personal de manera presencial, 
                           telefónica y vía electrónica.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-description-black"
                        align="left"
                        component="p">
                            Para nosotros, tu opinión es muy importante, por ende, ocasionalmente te contactaremos para
                            realizar encuestas de calidad, estudios de mercado y hacer de tu conocimiento 
                            las ofertas y promociones que tengamos en las diferentes temporadas del año.
                        </Typography>
                </Grid>

                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-header-black"
                        align="left"
                        component="p">
                            ¿Con quien se comparte tu información?
                        </Typography>
                </Grid>

                <Grid item xs={12} md={8} sm={8} >
                    <Typography variant="home-description-black"
                        align="left"
                        component="p">
                        CSE no compartirá o transferirá a terceros que no sean las marcas y/o fabricantes cuyos productos adquieres, los datos personales proporcionados, sin antes hacer de tu conocimiento dicha solicitud.
                        Puedes ejercer y/o administrar tus derechos de acceso, rectificación, cancelación y oposición, revocación de consentimiento o limitación de uso respecto al tratamiento de tus datos personales a través del correo ventas@csemoguel.com
                        Cualquier cambio o actualización al presente aviso de privacidad, se notificara a los titulares por todos los medios de comunicación disponibles.
 
                        </Typography>
                </Grid>
                
          
            </Grid>
                
            </Container>
        </VizSensor>
        </>
    );
}